import React, { useState } from 'react';
import '../styles/Enquiry.css'
import { countries } from '../countries';
import axios from 'axios';
import { toast } from 'react-toastify';


const Enquiry = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [course, setCourse] = useState("")
  const [country, setCountry] = useState("")
  const [loading, setLoading] = useState("")

  const handleEnquiry = async (e) => {
    setLoading(true)
    e.preventDefault()
    console.log(
      'name', name,
      'email', email,
      'phone', phone,
      'course', course,
      'country', country
    )

    const res = await axios.post(
      `https://lefttome.vineaglobal.com/api/enquiry`,
      {
        name,
        email,
        phone,
        course,
        country
      }
    );
    console.log('res', res)
    if(res?.data) {
      toast.success(res?.data?.message)
      setName("")
      setEmail("")
      setPhone("")
      setCourse("")
      setCountry("")
    }
    setLoading(false)
  }
    return (
        <div className='enquiry'>
        <div className="form">
        <svg
          viewBox="0 0 527 471"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M499.055 25.1624H253.684C238.725 25.1624 226.49 37.3966 226.49 52.3564V470.462H526.249V52.3564C526.249 37.3966 514.015 25.1624 499.055 25.1624Z"
            fill="#7F5C9A"
          />
          <path
            d="M444.584 234.08H308.156C294.423 234.08 283.188 222.845 283.188 209.113V72.6846C283.188 58.9523 294.423 47.7168 308.156 47.7168H444.584C458.316 47.7168 469.551 58.9523 469.551 72.6846V209.113C469.551 222.845 458.316 234.08 444.584 234.08Z"
            fill="#B6BE45"
          />
          <path
            d="M406.591 127.426V107.431L394.461 105.371C393.837 103.436 393.067 101.564 392.152 99.7951L399.267 89.7664L385.14 75.6388L375.111 82.7546C373.322 81.8391 371.449 81.0693 369.535 80.4451L367.475 68.3149H357.488H347.501L345.441 80.4451C343.506 81.0693 341.634 81.8391 339.865 82.7546L329.836 75.6388L315.709 89.7664L322.824 99.7951C321.909 101.584 321.139 103.457 320.515 105.371L308.385 107.431V127.426L320.515 129.486C321.139 131.421 321.909 133.294 322.824 135.062L315.709 145.091L329.836 159.218L339.865 152.103C341.654 153.018 343.527 153.788 345.441 154.412L347.501 166.542H357.488H367.475L369.535 154.412C371.47 153.788 373.343 153.018 375.111 152.103L385.14 159.218L399.267 145.091L392.152 135.062C393.067 133.273 393.837 131.4 394.461 129.486L406.591 127.426ZM357.467 139.203C345.441 139.203 335.683 129.444 335.683 117.418C335.683 105.392 345.441 95.6338 357.467 95.6338C369.493 95.6338 379.252 105.392 379.252 117.418C379.252 129.465 369.493 139.203 357.467 139.203Z"
            fill="white"
          />
          <path
            d="M444.397 189.429V177.133L436.948 175.863C436.553 174.677 436.095 173.533 435.533 172.43L439.902 166.272L431.205 157.574L425.047 161.944C423.944 161.382 422.8 160.904 421.614 160.529L420.344 153.08H414.206H408.069L406.799 160.529C405.613 160.924 404.469 161.382 403.366 161.944L397.208 157.574L388.51 166.272L392.88 172.43C392.318 173.533 391.839 174.677 391.465 175.863L384.016 177.133V189.429L391.465 190.698C391.86 191.884 392.318 193.029 392.88 194.131L388.51 200.29L397.208 208.987L403.366 204.618C404.469 205.18 405.613 205.658 406.799 206.033L408.069 213.481H414.206H420.344L421.614 206.033C422.8 205.637 423.944 205.18 425.047 204.618L431.205 208.987L439.902 200.29L435.533 194.131C436.095 193.029 436.573 191.884 436.948 190.698L444.397 189.429ZM414.186 196.67C406.799 196.67 400.786 190.678 400.786 183.27C400.786 175.884 406.779 169.871 414.186 169.871C421.593 169.871 427.585 175.863 427.585 183.27C427.585 190.678 421.593 196.67 414.186 196.67Z"
            fill="white"
          />
          <path
            d="M410.648 313.727H271.869C268.332 313.727 265.461 310.835 265.461 307.319C265.461 303.782 268.353 300.91 271.869 300.91H410.648C414.186 300.91 417.057 303.803 417.057 307.319C417.057 310.835 414.165 313.727 410.648 313.727Z"
            fill="#FD963A"
          />
          <path
            d="M348.229 272.488H271.869C268.332 272.488 265.461 269.596 265.461 266.08C265.461 262.543 268.353 259.672 271.869 259.672H348.229C351.766 259.672 354.637 262.564 354.637 266.08C354.637 269.596 351.745 272.488 348.229 272.488Z"
            fill="white"
          />
          <path
            d="M348.229 354.965H271.869C268.332 354.965 265.461 352.073 265.461 348.557C265.461 345.02 268.353 342.149 271.869 342.149H348.229C351.766 342.149 354.638 345.041 354.638 348.557C354.638 352.073 351.745 354.965 348.229 354.965Z"
            fill="white"
          />
          <path
            d="M406.154 440.813H276.364C268.395 440.813 261.862 434.28 261.862 426.311V411.331C261.862 403.362 268.395 396.829 276.364 396.829H406.154C414.123 396.829 420.656 403.362 420.656 411.331V426.311C420.656 434.28 414.123 440.813 406.154 440.813Z"
            fill="white"
          />
          <path
            d="M476.376 440.813H451.221C443.252 440.813 436.719 434.28 436.719 426.311V411.331C436.719 403.362 443.252 396.829 451.221 396.829H476.376C484.345 396.829 490.878 403.362 490.878 411.331V426.311C490.878 434.28 484.366 440.813 476.376 440.813Z"
            fill="#6EAACC"
          />
          <path
            d="M325.295 412.455V427H323.591L315.665 415.58H315.523V427H313.761V412.455H315.466L323.42 423.903H323.562V412.455H325.295ZM328.82 427V412.455H337.598V414.017H330.581V418.932H337.144V420.494H330.581V425.438H337.712V427H328.82ZM341.625 412.455L345.375 418.506H345.489L349.239 412.455H351.313L346.739 419.727L351.313 427H349.239L345.489 421.062H345.375L341.625 427H339.551L344.239 419.727L339.551 412.455H341.625ZM352.81 414.017V412.455H363.719V414.017H359.145V427H357.384V414.017H352.81Z"
            fill="#491800"
          />
          <path
            d="M476.792 430.327L471.362 424.896C475.169 420.319 474.982 413.536 470.696 409.25C466.16 404.714 458.774 404.714 454.217 409.25C449.681 413.786 449.681 421.172 454.217 425.728C456.423 427.934 459.336 429.141 462.457 429.141C465.203 429.141 467.783 428.142 469.884 426.394L475.315 431.825L476.792 430.327ZM455.694 424.21C451.97 420.485 451.97 414.431 455.694 410.706C457.546 408.854 460.001 407.918 462.436 407.918C464.87 407.918 467.325 408.854 469.177 410.706C472.901 414.431 472.901 420.485 469.177 424.21C467.367 426.02 464.974 426.998 462.436 426.998C459.897 427.018 457.505 426.02 455.694 424.21Z"
            fill="white"
          />
          <path
            d="M311.152 78.8222L283.188 66.6505L255.203 78.8222V16.4861H311.152V78.8222Z"
            fill="#D84F63"
          />
          <path
            d="M281.449 21.2496V34.1912H279.881V22.8925H279.806L276.646 24.9905V23.3981L279.881 21.2496H281.449Z"
            fill="white"
          />
          <path
            d="M121.896 135.437C136.835 133.46 166.901 160.446 177.366 189.97C187.832 219.495 205.351 253.784 205.351 253.784L308.135 189.97L316.853 200.811C316.853 200.811 214.984 284.89 202.272 287.49C189.58 290.112 150.63 230.439 143.306 200.811C135.982 171.161 121.896 135.437 121.896 135.437Z"
            fill="#BD574B"
          />
          <path
            d="M205.06 253.825C200.357 256.135 195.676 258.465 190.974 260.775C189.975 261.274 190.849 262.751 191.848 262.252C196.55 259.943 201.231 257.612 205.934 255.303C206.911 254.803 206.038 253.326 205.06 253.825Z"
            fill="#A84E43"
          />
          <path
            d="M147.946 122.162C158.745 122.89 164.3 106.661 164.029 104.622C161.928 89.3088 151.545 92.1801 150.755 89.2464C148.383 80.3828 160.971 58.9522 153.709 52.3981C151.587 50.4839 148.154 50.7752 145.845 49.0899C139.519 44.5125 146.094 32.2159 139.935 27.4304C137.46 25.5162 133.922 25.8283 131.051 24.5799C121.875 20.6267 123.935 4.35601 114.697 0.631651C110.827 -0.928834 106.25 0.569237 103.087 3.29488C99.9038 6.02053 97.4487 10.2859 95.4512 13.9686C91.1027 8.55891 84.0077 5.50037 77.0999 6.06214C70.1922 6.62391 63.6798 10.7852 60.2675 16.8191C58.4366 20.0441 57.3962 23.8309 54.733 26.3901C48.4494 32.4239 36.0696 29.2197 31.3465 36.5644C25.4999 45.6985 38.9617 59.5764 31.7835 67.6909C30.3894 69.2514 28.4544 70.2085 26.7691 71.4152C20.8185 75.6598 19.1748 86.8536 21.5467 93.7822C29.4948 116.961 13.2033 121.392 27.5598 132.565C67.8411 119.874 94.827 113.049 147.946 122.162Z"
            fill="#491800"
          />
          <path
            d="M65.3444 126.053C65.3444 126.053 84.2991 114.672 110.161 126.053C110.141 126.053 83.2171 144.321 65.3444 126.053Z"
            fill="#C8C9C8"
          />
          <path
            d="M121.272 140.056C94.8686 132.17 65.5315 137.58 38.9824 144.862C18.4464 150.501 9.95741 157.284 12.5582 170.038C17.9679 196.442 55.5652 321.717 52.3818 343.418L159.639 339.34L121.272 140.056Z"
            fill="#BD574B"
          />
          <path
            d="M32.9487 231.771C33.0528 233.519 30.7017 200.811 30.7017 200.811C30.7017 200.811 51.6954 218.704 51.7162 226.777C51.737 234.85 32.9487 231.771 32.9487 231.771Z"
            fill="#491800"
          />
          <path
            d="M159.618 339.34L52.361 343.418C52.3402 352.49 46.3063 381.66 17.2605 470.462L210.303 469.921C208.368 418.821 159.618 339.34 159.618 339.34Z"
            fill="#7BA9F7"
          />
          <path
            d="M174.911 391.356C173.996 389.525 173.164 387.549 171.666 386.134C171.312 385.801 170.833 386.03 170.854 386.508C170.937 388.568 171.853 390.503 172.643 392.397C173.434 394.29 174.058 396.454 175.577 397.889C176.222 398.514 177.179 398.098 177.138 397.182C177.034 395.101 175.806 393.187 174.911 391.356Z"
            fill="#6A91D4"
          />
          <path
            d="M184.981 412.017C184.066 410.186 183.234 408.21 181.736 406.795C181.382 406.462 180.903 406.691 180.924 407.169C181.007 409.229 181.923 411.164 182.714 413.057C183.504 414.951 184.128 417.115 185.647 418.55C186.292 419.175 187.249 418.758 187.208 417.843C187.104 415.742 185.876 413.848 184.981 412.017Z"
            fill="#6A91D4"
          />
          <path
            d="M166.838 373.213C165.923 371.382 165.091 369.406 163.593 367.991C163.239 367.658 162.76 367.887 162.781 368.365C162.864 370.425 163.78 372.36 164.57 374.254C165.361 376.147 165.985 378.311 167.504 379.746C168.149 380.371 169.106 379.954 169.065 379.039C168.961 376.958 167.754 375.044 166.838 373.213Z"
            fill="#6A91D4"
          />
          <path
            d="M193.949 432.491C193.241 430.577 192.617 428.538 191.265 426.956C190.953 426.603 190.433 426.769 190.412 427.248C190.266 429.308 190.994 431.326 191.577 433.282C192.16 435.237 192.555 437.464 193.907 439.045C194.49 439.732 195.468 439.399 195.53 438.504C195.655 436.423 194.656 434.405 193.949 432.491Z"
            fill="#6A91D4"
          />
          <path
            d="M201.419 455.627C201.002 453.63 200.69 451.507 199.588 449.76C199.338 449.364 198.797 449.448 198.693 449.905C198.256 451.924 198.651 454.025 198.943 456.043C199.213 458.061 199.296 460.329 200.399 462.077C200.878 462.847 201.897 462.66 202.084 461.786C202.521 459.768 201.835 457.624 201.419 455.627Z"
            fill="#6A91D4"
          />
          <path
            d="M49.8433 388.152C50.5299 386.237 51.1125 384.178 52.4441 382.576C52.7562 382.201 53.2556 382.367 53.2972 382.846C53.4636 384.906 52.777 386.924 52.236 388.901C51.6951 390.877 51.3206 393.104 49.9889 394.706C49.4064 395.392 48.4285 395.101 48.366 394.206C48.1788 392.105 49.1567 390.066 49.8433 388.152Z"
            fill="#6A91D4"
          />
          <path
            d="M43.9762 409.853C44.6628 407.939 45.2454 405.879 46.577 404.277C46.8891 403.903 47.3885 404.069 47.4301 404.548C47.5965 406.608 46.9099 408.626 46.369 410.602C45.828 412.579 45.4535 414.805 44.1219 416.407C43.5393 417.094 42.5614 416.803 42.499 415.908C42.3117 413.827 43.2896 411.788 43.9762 409.853Z"
            fill="#6A91D4"
          />
          <path
            d="M53.9008 368.552C54.3377 366.555 54.6706 364.433 55.7942 362.706C56.0438 362.31 56.5848 362.394 56.6888 362.872C57.1258 364.89 56.6888 366.992 56.3976 369.01C56.1063 371.028 56.0022 373.296 54.8995 375.044C54.4209 375.814 53.4014 375.627 53.2142 374.753C52.7356 372.693 53.4638 370.55 53.9008 368.552Z"
            fill="#6A91D4"
          />
          <path
            d="M36.985 433.802C37.6092 431.867 38.1294 429.786 39.3986 428.142C39.6899 427.768 40.21 427.913 40.2724 428.371C40.5221 430.41 39.8979 432.47 39.4194 434.446C38.9408 436.423 38.6495 438.67 37.3803 440.314C36.8186 441.021 35.8407 440.751 35.7366 439.856C35.4662 437.817 36.3816 435.737 36.985 433.802Z"
            fill="#6A91D4"
          />
          <path
            d="M30.0357 455.419C30.7848 453.526 31.4714 451.487 32.8446 449.968C33.1567 449.614 33.6769 449.802 33.6977 450.28C33.7809 452.34 33.0319 454.337 32.4077 456.293C31.7835 458.228 31.3258 460.454 29.9317 461.994C29.3283 462.66 28.3504 462.327 28.3296 461.412C28.2256 459.31 29.2867 457.313 30.0357 455.419Z"
            fill="#6A91D4"
          />
          <path
            d="M121.896 135.437C140.435 237.555 177.783 336.885 177.783 336.885C177.783 336.885 87.4409 350.763 41.2089 342.939C41.2089 342.939 44.2466 305.072 38.9826 278.918C35.5079 261.69 32.8239 250.975 26.145 232.79C26.145 232.79 94.6399 229.856 38.9826 144.883C38.9826 144.862 57.6044 132.17 121.896 135.437Z"
            fill="#FEFFFD"
          />
          <path
            d="M37.7757 235.787C37.2764 234.85 35.6535 235.495 36.028 236.515C41.2088 250.497 47.638 263.605 50.3012 278.419C52.9853 293.337 51.7993 308.464 45.2661 322.258C44.517 323.84 46.8265 325.234 47.638 323.652C61.7448 296.333 51.5912 261.191 37.7757 235.787Z"
            fill="#E7E8E6"
          />
          <path
            d="M167.795 320.073C149.153 325.67 130.094 329.665 110.807 331.871C100.819 333.015 90.7907 333.681 80.762 333.868C70.9206 334.055 61.0791 333.161 51.2377 333.369C50.2389 333.389 50.1557 334.784 51.1336 334.95C60.7462 336.531 70.879 336.261 80.6164 336.115C90.2498 335.949 99.8832 335.366 109.496 334.347C129.387 332.245 149.07 328.25 168.295 322.549C169.793 322.112 169.314 319.616 167.795 320.073Z"
            fill="#E7E8E6"
          />
          <path
            d="M121.896 135.437C121.896 135.437 161.324 182.168 163.696 195.546C166.089 208.925 139.166 223.261 127.68 224.01C127.701 223.989 90.6033 168.373 121.896 135.437Z"
            fill="#FEFFFD"
          />
          <path
            d="M141.288 219.599C132.882 216.478 121.98 219.162 113.47 220.806C102.921 222.845 92.3304 225.051 81.7399 222.034C79.9921 221.534 79.2431 224.26 80.9908 224.759C90.1457 227.422 99.571 226.278 108.788 224.468C113.782 223.49 118.734 222.283 123.748 221.534C129.657 220.64 135.192 221.139 141.101 220.972C141.933 220.952 142.016 219.87 141.288 219.599Z"
            fill="#E7E8E6"
          />
          <path
            d="M121.584 67.2955C121.584 67.2955 122.978 61.4905 128.658 62.2396C134.339 62.9886 135.795 86.1254 121.896 85.3347L121.584 67.2955Z"
            fill="#A84E43"
          />
          <path
            d="M70.1299 127.177L65.3444 84.9395L101.069 89.288L105.875 135.208C109.121 151.437 69.5473 166.688 70.1299 127.177Z"
            fill="#BD574B"
          />
          <path
            d="M101.069 89.288L105.376 128.883C105.376 128.883 88.2107 129.236 78.2444 120.477C68.0909 111.551 65.3236 84.9395 65.3236 84.9395L101.069 89.288Z"
            fill="#491800"
          />
          <path
            d="M124.248 37.8128C131.051 55.9769 127.826 113.007 105.855 119.249C91.3317 123.369 71.5656 108.16 65.3237 84.9187C54.7332 45.4696 55.1701 33.6516 68.0493 29.9896C96.2421 21.9999 118.963 23.7061 124.248 37.8128Z"
            fill="#BD574B"
          />
          <path
            d="M101.714 58.0157C101.756 59.0353 107.457 59.597 110.827 59.4514C114.177 59.3058 115.301 58.4111 115.259 57.4124C115.218 56.3929 111.077 55.7687 107.727 55.9143C104.357 56.0391 101.672 56.9962 101.714 58.0157Z"
            fill="#491800"
          />
          <path
            d="M109.745 71.2068C110.141 73.6827 111.41 75.5345 112.575 75.3473C113.74 75.16 114.385 73.0169 113.99 70.541C113.595 68.065 112.325 66.2132 111.16 66.4005C109.974 66.5877 109.35 68.7308 109.745 71.2068Z"
            fill="#491800"
          />
          <path
            d="M92.4758 59.9302C92.7463 60.9081 87.5031 63.2176 84.2573 64.1123C81.0115 65.0069 79.6798 64.5076 79.4094 63.5297C79.1389 62.5518 82.884 60.6584 86.109 59.7637C89.3756 58.869 92.2053 58.9523 92.4758 59.9302Z"
            fill="#491800"
          />
          <path
            d="M88.9805 75.0776C89.3759 77.5536 88.7309 79.6966 87.5657 79.8839C86.4005 80.0711 85.1105 78.2194 84.736 75.7434C84.3407 73.2674 84.9857 71.1244 86.1509 70.9371C87.316 70.7499 88.5852 72.6016 88.9805 75.0776Z"
            fill="#491800"
          />
          <path
            d="M75.2067 90.9318C75.2275 93.0124 78.0363 94.6978 81.511 94.677C84.9857 94.6561 87.7946 92.95 87.7738 90.8694C87.7529 88.7887 84.9441 87.1034 81.4694 87.1242C77.9947 87.145 75.2067 88.8303 75.2067 90.9318Z"
            fill="#DB6557"
          />
          <path
            d="M113.969 86.3752C113.99 88.4558 116.799 90.1411 120.273 90.1203C123.748 90.0995 126.557 88.3934 126.536 86.3127C126.515 84.2321 123.706 82.5468 120.232 82.5676C116.778 82.5884 113.969 84.2945 113.969 86.3752Z"
            fill="#DB6557"
          />
          <path
            d="M101.235 81.9849C101.901 83.4829 102.983 84.8145 104.336 85.7508C104.773 86.0629 105.251 86.3334 105.584 86.7495C105.938 87.1657 106.125 87.7483 105.896 88.2268C105.688 88.6845 105.189 88.9134 104.731 89.0799C103.92 89.3503 103.066 89.4544 102.213 89.4128"
            stroke="#4F2F3A"
            stroke-width="0.4132"
            stroke-miterlimit="10"
          />
          <path
            d="M64.5536 73.6417C63.9294 77.5325 60.3091 80.0085 56.4807 79.1762C52.6315 78.344 50.0307 74.4948 50.6549 70.604C51.2791 66.7132 54.8994 64.2372 58.7278 65.0695C62.577 65.9017 65.1778 69.7509 64.5536 73.6417Z"
            fill="#BD574B"
          />
          <path
            d="M55.2532 70.3333C57.6459 70.4997 59.9346 71.8521 61.2454 73.8495C59.7266 73.475 57.9372 73.7663 56.5848 74.5986"
            stroke="#4F2F3A"
            stroke-width="0.4132"
            stroke-miterlimit="10"
          />
          <path
            d="M105.064 104.789C102.921 106.1 99.7582 105.268 98.5306 103.062"
            stroke="#4F2F3A"
            stroke-width="0.4132"
            stroke-miterlimit="10"
          />
          <path
            d="M154.687 361.291C137.834 361.229 121.085 362.269 104.211 361.541C87.3783 360.812 70.7331 358.482 53.9631 357.255C53.4846 357.213 53.3805 358.004 53.8591 358.108C69.9633 361.665 86.9622 362.643 103.378 363.413C120.253 364.183 137.876 364.911 154.708 362.893C155.665 362.768 155.728 361.291 154.687 361.291Z"
            fill="#6A91D4"
          />
          <path
            d="M114.739 354.571C114.552 352.802 111.743 352.761 111.93 354.571C114.572 380.392 110.515 406.795 107.977 432.553C98.052 422.483 98.8011 407.107 99.7582 393.791C100.299 386.134 100.674 378.457 100.715 370.779C100.736 366.93 100.694 363.06 100.57 359.211C100.486 356.319 100.59 353.426 99.3836 350.784C99.2588 350.493 98.8427 350.618 98.7387 350.867C97.4903 354.467 98.1977 358.732 98.2393 362.519C98.2601 366.368 98.2393 370.238 98.1353 374.087C97.9064 382.555 97.1366 390.982 96.5956 399.43C95.7633 412.766 97.0117 427.518 108.102 436.59C109.1 437.401 110.453 436.86 110.578 435.57C113.074 408.855 117.527 381.39 114.739 354.571Z"
            fill="#6A91D4"
          />
          <path
            d="M120.793 33.4436C118.838 36.9599 115.051 39.4983 110.931 40.0392C108.31 40.3929 105.646 39.9768 103.025 40.2057C100.403 40.4345 97.5943 41.5165 96.4915 43.826C95.3264 46.302 96.5123 49.2357 95.909 51.8781C95.0143 55.8105 90.5201 57.9536 86.3796 58.5778C83.0298 59.0771 79.2846 59.0771 76.7462 61.2618C75.6227 62.2189 74.8944 63.5089 73.9373 64.6325C70.213 69.0226 62.9931 70.2294 57.9372 67.3165C56.8552 66.6923 55.8357 65.86 55.2947 64.7781C54.6289 63.4673 43.768 42.9313 63.3885 27.9299C71.3989 21.8128 92.5175 13.7191 113.053 17.381C120.814 18.775 123.935 27.8258 120.793 33.4436Z"
            fill="#491800"
          />
          <path
            d="M20.548 152.186C-7.5407 159.281 -2.06859 298.247 9.00045 328.833L35.9656 300.681C35.9656 300.661 38.1087 147.733 20.548 152.186Z"
            fill="#BD574B"
          />
          <path
            d="M38.0094 242.18L20.7998 251.384L82.4016 366.567L99.6112 357.363L38.0094 242.18Z"
            fill="#FFC965"
          />
          <path
            d="M171.166 319.096L99.6126 357.359L38.0046 242.174L109.558 203.911C127.722 243.527 148.3 281.998 171.166 319.096Z"
            fill="#FD963A"
          />
          <path
            d="M45.9165 256.959L28.7069 266.163L33.2402 274.64L50.4498 265.436L45.9165 256.959Z"
            fill="#FD963A"
          />
          <path
            d="M87.1603 334.089L69.9507 343.293L74.484 351.769L91.6936 342.565L87.1603 334.089Z"
            fill="#FD963A"
          />
          <path
            d="M74.4991 331.122C72.2936 332.308 69.5472 331.455 68.3612 329.27L44.0176 283.767C42.8525 281.561 43.6847 278.815 45.8694 277.629C48.0749 276.443 50.8213 277.296 52.0073 279.481L76.3509 324.984C77.5368 327.19 76.7046 329.957 74.4991 331.122Z"
            fill="#FEFFFD"
          />
          <path
            d="M30.2648 303.823L114.656 257.238L122.521 268.993C122.521 268.993 39.5237 341.442 20.0488 336.635C0.594767 331.829 8.62606 318.721 30.2648 303.823Z"
            fill="#BD574B"
          />
          <path
            d="M34.8836 299.413C30.6599 301.514 26.4153 303.615 22.1916 305.717C21.1929 306.216 22.0668 307.693 23.0655 307.194C27.2892 305.093 31.5337 302.991 35.7574 300.89C36.7353 300.411 35.8615 298.934 34.8836 299.413Z"
            fill="#A84E43"
          />
          <path
            d="M114.656 257.238C114.656 257.238 119.733 245.669 120.211 244.525C120.69 243.381 127.285 234.954 127.972 235.37C130.885 237.243 126.848 243.027 126.848 243.027C126.848 243.027 134.401 241.342 134.859 242.091C135.317 242.86 135.109 245.877 135.109 245.877C135.109 245.877 138.999 247.854 138.542 248.853C138.084 249.851 137.522 251.745 137.522 251.745C137.522 251.745 140.705 253.597 140.435 254.637C140.185 255.677 135.629 264.541 122.541 268.952L114.656 257.238Z"
            fill="#BD574B"
          />
          <path
            d="M308.135 189.97C308.135 189.97 308.426 175.905 310.756 173.575C313.087 171.245 317.227 173.429 319.266 176.384C319.266 176.384 332.645 187.89 316.874 200.811L308.135 189.97Z"
            fill="#BD574B"
          />
          <path
            d="M338.263 80.82C336.14 80.5911 321.201 176.904 317.539 200.728C317.102 203.641 317.976 205.888 319.516 205.742C321.035 205.596 322.616 203.162 323.053 200.291C326.59 176.759 340.884 81.0905 338.263 80.82Z"
            fill="#423137"
          />
          <path
            d="M331.667 171.89C333.207 173.679 330.002 174.927 330.19 175.822C330.377 176.717 334.185 178.028 334.039 179.318C333.893 180.608 331.605 181.96 331.605 181.96C331.605 181.96 333.394 182.376 333.456 184.394C333.519 186.413 325.425 192.28 323.282 191.552C321.139 190.823 320.036 187.973 323.053 185.83C323.053 185.83 318.975 185.663 319.183 183.937C319.37 182.21 321.409 181.065 321.409 181.065C321.409 181.065 317.102 181.315 317.331 179.463C317.56 177.611 320.744 176.654 320.744 176.654C320.744 176.654 316.145 174.678 318.621 173.492C321.076 172.306 329.857 169.788 331.667 171.89Z"
            fill="#A84E43"
          />
          <path
            d="M311.069 173.305C311.069 173.305 321.493 167.375 323.698 168.582C325.904 169.809 324.759 174.408 319.225 176.384C319.246 176.384 311.256 179.547 311.069 173.305Z"
            fill="#BD574B"
          />
          <path
            d="M65.3444 126.053L65.7813 146.194C65.7813 146.194 86.234 172.535 111.888 152.103L110.182 126.074C110.141 126.053 83.7789 133.127 65.3444 126.053Z"
            fill="#E7E8E6"
          />
        </svg>

        <form id="form" className="form_inner" onSubmit={handleEnquiry}>
          <div className="form_inner-title">Make Enquiry</div>
          <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          <input type="number" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} required />
          <input type="text" placeholder="Course to Study" value={course} onChange={(e) => setCourse(e.target.value)} required />
          <select name="" id="" value={country} onChange={(e) => setCountry(e.target.value)} required>
            <option className="" value="">
              Select Country to Study
            </option>
            {countries?.map((country) => <option value={country?.label}>{country?.label}</option>)}
          </select>

          <button type='submit'>{loading ? 'Loading' : 'Submit'}</button>
        </form>
      </div>
      </div>
    )
}

export default Enquiry;