import React, { useState } from "react";
import background from "../img/bg.jpg";
// import Students from "../img/OBJECT.png";
import Aust from "../img/australia.png";
import Student1 from "../img/Rectangle 31.png";
import Student2 from "../img/Rectangle 38.png";
import BlogImage from "../img/Rectangle 35.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "../styles/Contact.css";
import Header from "./Header";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "./Footer";
import {
  Select,
  MenuItem,
  Button,
  ListItemText,
  Grid,
  Checkbox,
  FormControl,
  Modal,
  Box,
  OutlinedInput,
  InputLabel,
  Badge,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";

const Contact = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState("")

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await axios.post('https://lefttome.vineaglobal.com/api/contact', {
      name,
      phone,
      email,
      message
    })
    if(res?.data) {
      toast.success(res.data.message)
      setName('')
      setEmail('')
      setPhone('')
      setMessage('')
    }
    setLoading(false)

  }
  return (
    <div className="contact">
      <Header />
      <div className="hero">
        <div className="hero_title-container">
          <div className="hero_title-bold">Contact Us</div>
        </div>
        <svg
          className="hero_img"
          width="388"
          height="344"
          viewBox="0 0 388 344"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.6046 343.57C31.1962 343.57 30.7824 343.482 30.3847 343.295C28.8178 342.559 28.0944 340.575 28.7671 338.867L93.5506 174.488C94.2233 172.777 96.0412 171.99 97.6081 172.724C99.1751 173.46 99.8985 175.444 99.2258 177.152L34.4422 341.531C33.943 342.804 32.8032 343.57 31.6046 343.57Z"
            fill="url(#paint0_linear_432_2898)"
          />
          <path
            d="M104.589 343.57C103.393 343.57 102.254 342.807 101.749 341.531L36.9655 177.152C36.2901 175.441 37.0162 173.458 38.5831 172.724C40.1501 171.987 41.9679 172.777 42.6406 174.488L107.424 338.867C108.1 340.578 107.373 342.561 105.807 343.296C105.409 343.482 104.995 343.57 104.589 343.57Z"
            fill="url(#paint1_linear_432_2898)"
          />
          <path
            d="M307.364 343.57C306.956 343.57 306.542 343.482 306.144 343.295C304.578 342.559 303.854 340.575 304.527 338.867L369.31 174.488C369.986 172.777 371.798 171.99 373.368 172.724C374.935 173.46 375.658 175.444 374.986 177.152L310.202 341.531C309.7 342.804 308.563 343.57 307.364 343.57Z"
            fill="url(#paint2_linear_432_2898)"
          />
          <path
            d="M380.345 343.57C379.149 343.57 378.009 342.807 377.505 341.531L312.721 177.152C312.046 175.441 312.772 173.458 314.339 172.724C315.906 171.987 317.724 172.777 318.396 174.488L383.18 338.867C383.855 340.578 383.129 342.561 381.562 343.296C381.167 343.482 380.754 343.57 380.345 343.57Z"
            fill="url(#paint3_linear_432_2898)"
          />
          <path
            d="M271.577 232.801H263.919V315.635H271.577V232.801Z"
            fill="url(#paint4_linear_432_2898)"
          />
          <path
            d="M301.294 317.234H234.207V316.874C234.207 314.319 236.279 312.248 238.833 312.248H296.308C299.063 312.248 301.294 314.479 301.294 317.234Z"
            fill="url(#paint5_linear_432_2898)"
          />
          <path
            d="M311.251 229.926C311.251 234.456 307.543 238.164 303.013 238.164H235.138C230.608 238.164 226.9 234.456 226.9 229.926V222.479C226.9 217.949 230.608 214.241 235.138 214.241H303.013C307.543 214.241 311.251 217.949 311.251 222.479V229.926Z"
            fill="url(#paint6_linear_432_2898)"
          />
          <path
            d="M211.203 177.571C211.203 177.571 199.821 179.336 187.024 187.891C185.011 189.237 183.906 191.586 184.207 193.988C185.74 206.126 192.039 245.411 212.815 283.784C213.91 285.807 214.225 288.162 213.742 290.412L210.132 307.211L203.438 313.486H220.679L232.158 282.42L227.249 280.866C223.442 279.662 220.831 276.163 220.762 272.172L220.185 238.847C220.677 224 211.083 213.755 211.083 213.755L238.618 212.415L211.203 177.571Z"
            fill="url(#paint7_linear_432_2898)"
          />
          <path
            d="M196.711 316.513C195.142 318.569 213.611 321.281 222.943 316.786C222.943 316.786 235.879 294.28 235.879 282.692C235.879 282.692 234.852 280.33 227.257 280.866C227.257 280.866 222.914 307.515 218.328 310.499C213.742 313.484 205.496 310.801 205.496 310.801C205.496 310.801 199.026 313.481 196.711 316.513Z"
            fill="url(#paint8_linear_432_2898)"
          />
          <path
            d="M235.14 214.241C234.993 213.987 205.494 210.693 205.494 210.693C205.494 210.693 206.46 219.662 203.441 232.008C200.425 244.354 157.114 297.849 157.114 297.849L161.654 312.245C147.613 317.135 133.802 317.917 120.212 314.738L148.355 297.849C148.355 297.849 174.694 208.088 190.828 192.077L220.397 181.848L235.14 214.241Z"
            fill="url(#paint9_linear_432_2898)"
          />
          <path
            d="M301.296 162.014C301.296 162.014 318.292 187.293 319.685 198.825C321.079 210.357 315.03 215.042 297.198 215.813C279.364 216.585 231.758 216.574 231.758 216.574L191.127 181.85C191.127 181.85 220.739 171.285 228.437 168.298C236.133 165.308 301.296 162.014 301.296 162.014Z"
            fill="url(#paint10_linear_432_2898)"
          />
          <path
            d="M159.941 307.4C159.941 307.4 164.831 312.136 161.526 317.405C161.526 317.405 122.796 324.271 112.281 321.155C112.281 321.155 114.465 316.172 126.306 310.499L125.433 311.169C124.328 312.018 124.977 313.785 126.368 313.708C134.168 313.259 150.315 311.831 159.941 307.4Z"
            fill="url(#paint11_linear_432_2898)"
          />
          <path
            d="M243.393 13.8813C246.046 13.4409 255.808 12.237 257.421 18.091C257.634 18.8678 258.601 19.1294 259.201 18.5928C262.279 15.838 269.537 10.6621 277.497 14.3138C284.523 17.5384 283.874 24.2519 282.66 28.3708C282.366 29.3665 283.453 30.1914 284.312 29.6148C290.444 25.4852 304.57 18.1123 313.62 30.2661C322.057 41.6004 315.176 49.4671 310.544 53.0441C309.76 53.65 310.133 54.8913 311.118 54.99C314.498 55.3237 319.153 57.1816 321.297 64.4077C324.652 75.7339 303.876 85.1462 301.431 86.2113C301.276 86.2781 301.111 86.3074 300.943 86.2994L258.977 84.1479C258.649 84.1319 258.347 83.969 258.152 83.7047L232.408 49.024C232.2 48.7437 232.139 48.3807 232.24 48.047L242.536 14.6448C242.654 14.2444 242.985 13.9508 243.393 13.8813Z"
            fill="url(#paint12_linear_432_2898)"
          />
          <path
            d="M255.334 158.667C255.334 158.667 235.543 139.821 225.8 127.339C220.533 120.59 218.881 111.709 221.315 103.504C225.763 88.5177 233.667 72.9979 234.387 70.5821C235.492 66.8796 257.683 67.0344 257.683 67.0344C257.683 67.0344 301.352 95.3754 318.508 143.384L299.24 149.383C299.24 149.383 287.839 130.254 276.572 118.092C276.572 118.092 296.066 143.844 296.752 154.385L255.334 158.667Z"
            fill="url(#paint13_linear_432_2898)"
          />
          <path
            d="M231.705 49.8915C231.937 50.6603 237.73 69.864 238.181 70.2617C238.635 70.6595 250.885 71.3188 256.648 67.3788L246.867 46.9018L231.705 49.8915Z"
            fill="url(#paint14_linear_432_2898)"
          />
          <path
            d="M215.336 22.8052C215.336 22.8052 210.501 33.0743 215.301 49.0693C220.101 65.0644 228.712 66.7114 234.315 62.9689C239.918 59.2291 247.246 52.4595 248.578 38.1489C249.912 23.8382 221.958 9.8319 215.336 22.8052Z"
            fill="url(#paint15_linear_432_2898)"
          />
          <path
            d="M237.914 36.8808C237.914 36.8808 229.022 15.1572 214.261 28.0531L212.982 33.5334C212.982 33.5334 199.443 29.2143 204.322 16.9751C209.199 4.73859 225.331 -4.94598 239.689 3.53469C254.048 12.0154 259.173 23.4217 258.033 34.6652C256.894 45.9087 248.57 41.7871 248.57 41.7871C248.57 41.7871 247.206 33.4506 243.285 31.8543C239.364 30.258 239.252 36.5311 239.252 36.5311L237.914 36.8808Z"
            fill="url(#paint16_linear_432_2898)"
          />
          <path
            d="M241.194 37.2466C241.194 37.2466 236.558 26.1072 243.546 25.9284C250.535 25.7495 247.353 36.646 245.836 40.61L241.194 37.2466Z"
            fill="url(#paint17_linear_432_2898)"
          />
          <path
            d="M215.014 160.066L229.554 146.049L249.67 154.545C249.67 154.545 269.694 157.647 281.153 154.545C292.613 151.443 292.613 151.443 292.613 151.443L318.509 143.382L322.577 154.876C323.61 157.794 322.572 161.035 320.049 162.828C317.809 164.419 315.183 166.17 313.509 166.902C310.098 168.394 229.439 165.869 229.439 165.869L215.014 160.066Z"
            fill="url(#paint18_linear_432_2898)"
          />
          <path
            d="M270.039 166.675C269.975 166.678 269.911 166.68 269.844 166.68L185.494 166.589C183.409 166.587 181.719 164.894 181.722 162.81C181.724 160.725 183.422 159 185.502 159.038L269.852 159.129C271.937 159.131 273.626 160.824 273.624 162.908C273.624 164.926 272.035 166.576 270.039 166.675Z"
            fill="url(#paint19_linear_432_2898)"
          />
          <path
            d="M109.667 90.0872H179.915C185.096 90.0872 189.813 93.0769 192.024 97.7644L224.499 166.627H137.199C133.86 166.627 130.86 164.587 129.632 161.48L104.449 97.7591C102.996 94.078 105.709 90.0872 109.667 90.0872Z"
            fill="url(#paint20_linear_432_2898)"
          />
          <path
            d="M159.158 125.395C160.679 130.126 159.556 134.717 156.646 135.654C153.736 136.591 150.143 133.516 148.622 128.786C147.1 124.055 148.224 119.464 151.134 118.527C154.043 117.593 157.636 120.668 159.158 125.395Z"
            fill="url(#paint21_linear_432_2898)"
          />
          <path
            d="M387.931 169.755C388.145 168.8 387.854 167.809 387.141 167.059C386.429 166.309 385.363 165.871 384.242 165.871H4.32578C2.84961 165.871 1.47756 166.525 0.682083 167.604C-0.113397 168.682 -0.220189 170.038 0.399111 171.202L4.51533 178.941C5.45763 180.713 7.49971 181.848 9.74734 181.848H381.634C383.724 181.848 385.524 180.561 385.919 178.778L387.931 169.755Z"
            fill="url(#paint22_linear_432_2898)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_432_2898"
              x1="108.679"
              y1="43.3864"
              x2="32.9576"
              y2="407.118"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#18459F" />
              <stop offset="1" stop-color="#2874B5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_432_2898"
              x1="116.534"
              y1="45.0217"
              x2="40.8132"
              y2="408.752"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#18459F" />
              <stop offset="1" stop-color="#2874B5" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_432_2898"
              x1="372.984"
              y1="98.409"
              x2="297.262"
              y2="462.141"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#18459F" />
              <stop offset="1" stop-color="#2874B5" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_432_2898"
              x1="380.837"
              y1="100.044"
              x2="305.116"
              y2="463.774"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#18459F" />
              <stop offset="1" stop-color="#2874B5" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_432_2898"
              x1="277.617"
              y1="383.353"
              x2="262.292"
              y2="213.874"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FDF53F" />
              <stop offset="1" stop-color="#D93C65" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_432_2898"
              x1="273.771"
              y1="383.701"
              x2="258.446"
              y2="214.223"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FDF53F" />
              <stop offset="1" stop-color="#D93C65" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_432_2898"
              x1="283.24"
              y1="382.845"
              x2="267.914"
              y2="213.366"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FDF53F" />
              <stop offset="1" stop-color="#D93C65" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_432_2898"
              x1="184.16"
              y1="245.526"
              x2="238.623"
              y2="245.526"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F9B776" />
              <stop offset="1" stop-color="#F47960" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_432_2898"
              x1="217.484"
              y1="281.014"
              x2="209.474"
              y2="403.843"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#CB236D" />
              <stop offset="1" stop-color="#4C246D" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_432_2898"
              x1="120.212"
              y1="249.246"
              x2="235.14"
              y2="249.246"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F9B776" />
              <stop offset="1" stop-color="#F47960" />
            </linearGradient>
            <linearGradient
              id="paint10_linear_432_2898"
              x1="160.476"
              y1="189.293"
              x2="289.228"
              y2="189.293"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#2B3582" />
              <stop offset="1" stop-color="#150E42" />
            </linearGradient>
            <linearGradient
              id="paint11_linear_432_2898"
              x1="140.183"
              y1="275.973"
              x2="132.173"
              y2="398.802"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#CB236D" />
              <stop offset="1" stop-color="#4C246D" />
            </linearGradient>
            <linearGradient
              id="paint12_linear_432_2898"
              x1="215.294"
              y1="-5.4821"
              x2="328.226"
              y2="94.0203"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#2B3582" />
              <stop offset="1" stop-color="#150E42" />
            </linearGradient>
            <linearGradient
              id="paint13_linear_432_2898"
              x1="127.249"
              y1="316.106"
              x2="311.152"
              y2="58.2817"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#A737D5" />
              <stop offset="1" stop-color="#EF497A" />
            </linearGradient>
            <linearGradient
              id="paint14_linear_432_2898"
              x1="249.301"
              y1="91.5121"
              x2="243.552"
              y2="54.6263"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F9B776" />
              <stop offset="1" stop-color="#F47960" />
            </linearGradient>
            <linearGradient
              id="paint15_linear_432_2898"
              x1="211.166"
              y1="43.2589"
              x2="271.994"
              y2="35.0205"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F9B776" />
              <stop offset="1" stop-color="#F47960" />
            </linearGradient>
            <linearGradient
              id="paint16_linear_432_2898"
              x1="208.076"
              y1="2.71183"
              x2="321.008"
              y2="102.214"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#2B3582" />
              <stop offset="1" stop-color="#150E42" />
            </linearGradient>
            <linearGradient
              id="paint17_linear_432_2898"
              x1="210.439"
              y1="37.8907"
              x2="271.267"
              y2="29.6524"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F9B776" />
              <stop offset="1" stop-color="#F47960" />
            </linearGradient>
            <linearGradient
              id="paint18_linear_432_2898"
              x1="282.302"
              y1="155.38"
              x2="390.263"
              y2="155.38"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F9B776" />
              <stop offset="1" stop-color="#F47960" />
            </linearGradient>
            <linearGradient
              id="paint19_linear_432_2898"
              x1="284.351"
              y1="144.262"
              x2="217.391"
              y2="166.223"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#2B3582" />
              <stop offset="1" stop-color="#150E42" />
            </linearGradient>
            <linearGradient
              id="paint20_linear_432_2898"
              x1="192.368"
              y1="100.358"
              x2="144.146"
              y2="151.326"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#2B3582" />
              <stop offset="1" stop-color="#150E42" />
            </linearGradient>
            <linearGradient
              id="paint21_linear_432_2898"
              x1="163.216"
              y1="113.048"
              x2="141.336"
              y2="145.997"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#53D8FF" />
              <stop offset="1" stop-color="#3840F7" />
            </linearGradient>
            <linearGradient
              id="paint22_linear_432_2898"
              x1="189.617"
              y1="190.418"
              x2="113.895"
              y2="554.149"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#18459F" />
              <stop offset="1" stop-color="#2874B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="contact_form">
        <div className="contact_form-title">Let's talk</div>
        <div className="contact_form-subtitle">Ask us anything...</div>
        <div className="contact_form-container">
          <form className="contact_form-inner" onSubmit={handleSubmit}>
            <input type="text" placeholder="Full Name" required value={name} onChange={(e) => setName(e.target.value)} />
            <input type="email" placeholder="Email" required value={email} onChange={(e) => setEmail(e.target.value)} />
            <input type="number" placeholder="Phone number" required value={phone} onChange={(e) => setPhone(e.target.value)} />
            <textarea name="" id="" cols="30" rows="10" placeholder="Type your message here..." required value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
            <button type="submit" className="contact_button">{loading ? 'Loading...' : 'Send message'}</button>
          </form>
          <div className="contact_info-container">
            <div className="info_container-title">Contact Us</div>
            <div className="info_container-flex">
              <svg
                width="24"
                height="21"
                viewBox="0 0 24 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.8635 0C20.4497 0 23.1538 2.926 23.1538 6.80615V14.2025C23.1538 16.1883 22.4517 17.9825 21.1755 19.2554C20.0308 20.3958 18.5457 21 16.8808 21H6.26985C4.60815 21 3.12415 20.3969 1.97831 19.2554C0.702154 17.9825 0 16.1883 0 14.2025V6.80615C0 2.926 2.70415 0 6.29031 0H16.8635ZM16.8635 1.61538H6.29031C3.58185 1.61538 1.61538 3.79831 1.61538 6.80615V14.2025C1.61538 15.7565 2.14954 17.1446 3.11877 18.1106C3.95446 18.9452 5.04539 19.3846 6.27308 19.3846H16.8635C16.8657 19.3825 16.8743 19.3846 16.8808 19.3846C18.1095 19.3846 19.1994 18.9452 20.0351 18.1106C21.0054 17.1446 21.5385 15.7565 21.5385 14.2025V6.80615C21.5385 3.79831 19.572 1.61538 16.8635 1.61538ZM18.5608 6.60025C18.8418 6.94594 18.7891 7.45425 18.4434 7.7364L13.6575 11.6262C13.0523 12.1066 12.3286 12.3467 11.606 12.3467C10.8855 12.3467 10.1672 12.1087 9.56631 11.6327L4.73631 7.73855C4.38846 7.45855 4.33462 6.94917 4.61354 6.6024C4.89462 6.25671 5.40292 6.20178 5.74969 6.48071L10.5754 10.3706C11.1817 10.8509 12.0357 10.8509 12.6463 10.3662L17.4235 6.48286C17.7703 6.19963 18.2786 6.25348 18.5608 6.60025Z"
                  fill="black"
                />
              </svg>
              <div>info@vineaglobal.com</div>
            </div>
            <div className="info_container-flex">
              <svg
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.10715 4.0319C3.10978 4.0319 3.04393 4.10039 2.96096 4.18204C2.64223 4.49155 1.98239 5.13559 1.97573 6.48294C1.96526 8.36764 3.20461 11.8671 9.17218 17.8333C15.1121 23.7706 18.6062 25.0244 20.4949 25.0244H20.5226C21.8699 25.0178 22.5126 24.3567 22.8234 24.0393C22.9183 23.9418 22.9947 23.8707 23.0487 23.8259C24.3604 22.5062 25.0321 21.5237 25.0256 20.8941C25.0163 20.2514 24.2169 19.4915 23.1119 18.4405C22.7602 18.1059 22.3783 17.7424 21.9753 17.3394C20.9308 16.2976 20.4132 16.4754 19.2753 16.8758C17.7014 17.4277 15.5415 18.1784 12.1856 14.8212C8.82448 11.4627 9.57652 9.30538 10.127 7.7315C10.5248 6.59357 10.7065 6.07465 9.66081 5.02891C9.25121 4.62062 8.88375 4.23341 8.54527 3.8778C7.50084 2.77938 6.74749 1.9852 6.10872 1.97598H6.09818C5.46731 1.97598 4.48742 2.65031 3.10057 4.03717C3.10452 4.03322 3.10715 4.0319 3.10715 4.0319ZM20.4962 27C17.2141 27 12.935 24.387 7.77611 19.2307C2.59745 14.0534 -0.019535 9.76108 0.00010978 6.4724C0.0120743 4.29926 1.15133 3.18635 1.57937 2.76885C1.60176 2.74119 1.67815 2.66612 1.70449 2.63977C3.59314 0.749802 4.86673 -0.0140892 6.13374 0.000196422C7.60489 0.0201542 8.65195 1.12121 9.9769 2.51597C10.3048 2.86104 10.6605 3.2364 11.0569 3.63152C12.9798 5.55441 12.4319 7.12302 11.992 8.38213C11.5126 9.75581 11.0977 10.9412 13.5817 13.4251C16.0683 15.9091 17.2536 15.4942 18.622 15.0109C19.8825 14.571 21.4471 14.0204 23.3727 15.9433C23.7625 16.3332 24.1326 16.6848 24.4737 17.0101C25.8751 18.3417 26.9814 19.394 27.0001 20.8691C27.0156 22.1269 26.2517 23.4084 24.3657 25.2957L23.5307 24.7294L24.2366 25.4195C23.8191 25.8476 22.7075 26.9881 20.5331 27H20.4962Z"
                  fill="black"
                />
              </svg>

              <div>+2348090184333</div>
            </div>
            <div className="info_container-flex">
              <svg
                width="23"
                height="28"
                viewBox="0 0 23 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.5 0.181885C17.841 0.181885 23 5.37988 23 11.7697C23 19.6246 14.0007 27.3637 11.5 27.3637C8.99927 27.3637 0 19.6246 0 11.7697C0 5.37988 5.15897 0.181885 11.5 0.181885ZM11.5 2.27279C6.31176 2.27279 2.09091 6.53407 2.09091 11.7697C2.09091 18.4313 9.93042 24.9215 11.5 25.2672C13.0696 24.9201 20.9091 18.4299 20.9091 11.7697C20.9091 6.53407 16.6882 2.27279 11.5 2.27279ZM11.5014 7.15158C13.9993 7.15158 16.0317 9.18395 16.0317 11.6833C16.0317 14.1812 13.9993 16.2122 11.5014 16.2122C9.00345 16.2122 6.97109 14.1812 6.97109 11.6833C6.97109 9.18395 9.00345 7.15158 11.5014 7.15158ZM11.5014 9.24249C10.1562 9.24249 9.062 10.3367 9.062 11.6833C9.062 13.0284 10.1562 14.1213 11.5014 14.1213C12.8465 14.1213 13.9408 13.0284 13.9408 11.6833C13.9408 10.3367 12.8465 9.24249 11.5014 9.24249Z"
                  fill="black"
                />
              </svg>

              <div>H66, off Next Cash and Carry, Jahi Plaza, FCT, Abuja</div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Contact;
